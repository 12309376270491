<template>
  <div v-if="isValidated" class="d-flex flex-grow-1 flex-column protection-quote-form" v-bind:style="$store.getters.mainBg">
    <b-row class="pt-4 pb-4 pl-8 pr-8" align-v="center" v-if="$store.getters.hasHeader" v-bind:style="$store.getters.headerBackground">
      <b-col>
        <img
          :src="$store.getters.logoPath"
          class="h-50px align-self-center"
          alt=""
        />
      </b-col>
      <b-col class="text-center">
        <div class="h1 font-weight-bolder" v-bind:style="$store.getters.headerTitleColor">{{$store.getters.headerText}}</div>
      </b-col>
      <b-col class="text-right">
        <span v-if="$store.getters.phoneNumberText" class="h4" v-bind:style="$store.getters.headerTitleColor">Need help? Call</span><br/>
        <span class="h1" v-bind:style="$store.getters.headerTitleColor">{{$store.getters.phoneNumberText}}</span>
      </b-col>
    </b-row>
    <div class="p-2 main-protection-container align-self-center">
      <b-row>
        <b-col md="12">
          <b-nav class="border-0" tabs justified v-model="tabIndex">
            <b-nav-item
              v-bind:style="$store.getters.tabsBox"
              class="quote-tab" 
              :to="{ name: 'ProtectionQuoteForm' }" 
              :active="this.$route.name == 'ProtectionQuoteForm'"
              >
              <div class="d-flex flex-column align-self-start justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                <div class="d-flex justify-content-center mb-2">
                  <div class="tab-number d-flex justify-content-center align-items-center" v-bind:style="[this.$route.name == 'ProtectionQuoteForm' ? $store.getters.tabBorderActive : $store.getters.tabBorder]">
                    <div>1</div>
                  </div>
                </div>
                <div class="align-self-center tab-text">You and<br/>Your Cover</div>
              </div>
            </b-nav-item>
            
            <b-nav-item 
              v-bind:style="$store.getters.tabsBox"
              class="quote-tab" 
              :to="{ name: 'ProtectionQuoteLifestyle' }"
              :active="this.$route.name == 'ProtectionQuoteLifestyle'"
              >
              <div class="d-flex flex-column align-self-start justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                <div class="d-flex justify-content-center mb-2">
                  <div class="tab-number d-flex justify-content-center align-items-center" v-bind:style="[this.$route.name == 'ProtectionQuoteLifestyle' ? $store.getters.tabBorderActive : $store.getters.tabBorder]">
                    <div>2</div>
                  </div>
                </div>
                <div class="align-self-center tab-text">Health and<br/>Lifestyle</div>
              </div>
            </b-nav-item>

            <b-nav-item 
              v-bind:style="$store.getters.tabsBox"
              class="quote-tab" 
              :to="{ name: 'ProtectionQuoteResult' }" 
              :active="this.$route.name == 'ProtectionQuoteResult'"
              >
              <div class="d-flex flex-column align-self-start justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                <div class="d-flex justify-content-center mb-2">
                  <div class="tab-number d-flex justify-content-center align-items-center" v-bind:style="[this.$route.name == 'ProtectionQuoteResult' ? $store.getters.tabBorderActive : $store.getters.tabBorder]">
                    <div>3</div>
                  </div>
                </div>
                <div class="align-self-center tab-text">Your<br/>Quotes</div>
              </div>
            </b-nav-item>
            
            <b-nav-item
              v-bind:style="$store.getters.tabsBox"
              class="quote-tab" 
              :to="{ name: 'ProtectionQuoteApplication'}" 
              v-bind:disabled="hasProductSelected" 
              :active="this.$route.name == 'ProtectionQuoteApplication'"
              >
              <div class="d-flex flex-column align-self-start justify-content-center flex-grow-1" v-bind:style="$store.getters.tabsText">
                <div class="d-flex justify-content-center mb-2">
                  <div class="tab-number d-flex justify-content-center align-items-center" v-bind:style="[this.$route.name == 'ProtectionQuoteApplication' ? $store.getters.tabBorderActive : $store.getters.tabBorder]">
                    <div>4</div>
                  </div>
                </div>
                <div class="align-self-center tab-text">Your<br/>Application</div>
              </div>
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>
      <router-view class="plugin-container"/>
    </div>
  </div>
</template>

<script>
import { VALIDATE_FORM } from "@/core/services/store/auth.module";

export default {
  name: "protection-quote",
  data() {
    return {
      tabIndex: 1
    }
  },
  computed: {
    isValidated: {
      get(){
        return this.$store.state.auth.isValidated;
      }
    },
    hasResults: {
      get() {
        return this.$store.state.protection.protectionQuoteResults.length == 0;
      }
    },
    hasProductSelected: {
      get() {
        return Object.keys(this.$store.state.protection.selectedProduct).length == 0;
      }
    },
    hasCorrectInfo: {
      get() {
        return this.$store.state.protection.quoteApplication.isInformationCorrect;
      }
    },
    hasApplicationDetails: {
      get() {
        return this.$store.state.protection.quoteApplication.hasApplicationDetails;
      }
    },
    displayMode: {
      get() {
        return this.$route.query.display;
      }
    }
  },
  watch: {
    '$route.query.colorSettingId': function(){
      this.$store.dispatch("getColorSettingById", this.$route.query.colorSettingId)
      .then(() => {})
    },
    '$route.query.reloadHash': function(){
      this.$store.dispatch("getColorSettingById", this.$route.query.colorSettingId)
      .then(() => {})
    },
    '$route.query.appKey': function(){
      console.log(this.$route.query.appKey);
    }
  },
  created: function(){
    window.addEventListener("resize", this.screenResize);
    this.ready(function(){
      setTimeout(function(){
        window.dispatchEvent(new Event('resize'));
      }, 1000);
    });

    var colorSettingId = this.$route.query.colorSettingId;
     
    if(this.$route.query.displayMode){
      this.$store.commit("updateDisplayMode", this.$route.query.displayMode);
      this.initializePlugin({});
      this.$store.dispatch("getColorSettingById", colorSettingId)
      .then(() => {})
      .catch(() => {});
    }
  },
  methods: {
    screenResize(){
      this.$resizeFrame();
    },
    initializePlugin(credentials){
      console.log(credentials);
      this.$store.dispatch(VALIDATE_FORM, credentials)
      .then(data => {
        var pluginMessage = { messageType: "validated" };
        window.parent.postMessage(JSON.stringify(pluginMessage),"*");

        this.$store.dispatch("getColorSettingById", data.colorSettingId)
        .then(() => {})
        .catch(() => {});
      })
      .catch(() => {});
    },
    ready: function ready(callback){
      if (document.readyState!='loading') callback();
      else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
      else document.attachEvent('onreadystatechange', function(){
          if (document.readyState=='complete') callback();
      });
    }
  },
  mounted: function(){
    const _this = this;
    window.addEventListener("message", function(res){
      console.log(res);
      var messageData = {};
      try{
        messageData = JSON.parse(res.data);
      } catch(err){
        console.log(err);
      }
      
      if(messageData.hasOwnProperty("messageType") && messageData.messageType === "validate"){
        _this.initializePlugin(messageData.payload);
      }

      if(messageData.hasOwnProperty("messageType") && messageData.messageType === "reloadPreview"){
        _this.$store.dispatch("getColorSettingById", messageData.payload.colorSettingId).then(() => {})
      }

      if(messageData.hasOwnProperty("messageType") && messageData.messageType === "loadQuote"){
        _this.$resetFrame();
        _this.$store.commit("updateLoadingStatus", true);
        _this.$store.commit("updateViewMode", false);
        _this.$store.commit("updateEditMode", false);

        _this.$store.dispatch("getPreviousProtectionQuote", messageData.payload.quoteRef)
          .then(() => {
            _this.$router.push({ name: 'ProtectionQuoteResult' });
            _this.$store.commit("updateLoadingStatus", false);
          })
          .catch(() => {
            _this.$store.commit("updateLoadingStatus", false);
          });
      }
    }, false);
  },
  destroyed: function(){
    window.removeEventListener("resize", this.screenResize);
  }
}
</script>

<style lang="scss">
.quote-tab {
  font-size: 16px;
  font-weight: 800;
  position: relative;

  a {
    padding: 15px !important;
    text-align: center;
  }

  .tab-icon {
    position: absolute;
    top: 5px;
    left: 5px;
  }
}

.plugin-container {
  padding: 1.5rem !important;
}

.bg {
  background-color: #077EE2;
  height: 150px;
  width: 100%;
  position: absolute;
}

.quote-tab {
  .nav-link {
    height: 100%;
    background: transparent;

    &:not(.active){  
      border-bottom: 0;
      opacity: 0.3;

      .tab-text, .tab-icon {
        color: #FFFFFF !important;  
      }
    }
  }
}

.tab-number {
  border: solid 3px #000;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.nav-item.quote-tab {
  background: transparent !important;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: transparent !important;
}

.nav-link {
  border: none !important;
}

.quote-tab .nav-link:not(.active) .tab-text, 
.quote-tab .nav-link:not(.active) .tab-icon {
  color: unset !important;
}

.main-protection-container {
  max-width: 644px;
}

.quote-tab .nav-link {
  padding: 0 !important;
  padding-top: 20px !important;
}

.tab-text {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}

.form-control, .custom-select, .btn {
  border-color: $darker-grey;
}

.form-group {
  margin-bottom: 1rem;
}

.btn {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .plugin-container {
    padding: 0.75rem !important;
  }

  .form-control, .custom-select, .btn {
    min-height: 30px !important;
    font-size: 13px !important;
  }

  .form-group {
    margin-bottom: 1.25rem;
  }

  .tab-text {
    font-size: 15px;
  }

  .btn {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .form-control, .custom-select, .btn {
    min-height: 50px !important;
    font-size: 14px !important;
  }

  .btn {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
  }

  * {
    font-size: 14px;
  }
  
  .form-group {
    margin-bottom: 1.25rem;
  }

  .tab-text {
    font-size: 17px;
  }
}
</style>
